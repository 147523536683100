import React from 'react';
import Container from './Container';
import { Row, Col } from 'emotion-flex';
import { MonoFontLabel } from '../common/typography';
import Perk from './Perk';
import { ButtonLink } from '../common/Button';
import presets from '../../styles/presets';
import { ContentfulContentBlock } from '../../graphql-types';
import { SYSTEM_FONTS } from '../../styles/typography';

import image1 from '../../assets/images/homepage-program1.svg';
import image2 from '../../assets/images/homepage-program2.svg';
import image3 from '../../assets/images/homepage-program3.svg';
import image4 from '../../assets/images/homepage-program4.svg';

const images = [image1, image2, image3, image4];

export default function PersonalizedPrograms({
  prefix,
  title,
  items,
  callToAction,
}: ContentfulContentBlock) {
  return (
    <Container
      css={{
        marginBottom: 112,
        marginTop: 112,
        [presets.lg]: { marginBottom: 100, marginTop: 100 },
      }}
    >
      <Row>
        <Col xs={12} lg={4}>
          <MonoFontLabel>{prefix}</MonoFontLabel>
          <h4
            css={{
              fontFamily: ['Blacker Pro Display']
                .concat(SYSTEM_FONTS)
                .join(', '),
              fontSize: 32,
              color: '#160B2C',
              marginBottom: 48,
            }}
            dangerouslySetInnerHTML={{ __html: title }}
          />
          {callToAction && callToAction.isDisplayed && callToAction.url && (
            <ButtonLink
              to={callToAction.url}
              css={{
                display: 'none',
                visibility: 'hidden',
                [presets.lg]: {
                  display: 'inline-block',
                  visibility: 'visible',
                },
              }}
            >
              {callToAction.text}
            </ButtonLink>
          )}
        </Col>
        <Col xs={12} lg={7} lgOffset={1}>
          <Row>
            {items &&
              items.map((item, index) => (
                <Col key={item.id} xs={12} lg={6}>
                  <Perk
                    image={images[index]}
                    title={item.heading}
                    text={item.shortText}
                  />
                </Col>
              ))}
          </Row>
          {callToAction && callToAction.isDisplayed && callToAction.url && (
            <ButtonLink
              to={callToAction.url}
              css={{
                visibility: 'visible',
                [presets.lg]: {
                  display: 'none',
                  visibility: 'hidden',
                },
              }}
            >
              {callToAction.text}
            </ButtonLink>
          )}
        </Col>
      </Row>
    </Container>
  );
}
